import React from 'react'
import "./OrderOnline.css";

const OrderOnline = () => {
  return (
    <div id="orderOnline" className='innerWidth paddings flexCenter o-wrapper'>
        <h1>page<br />under<br />maintenance</h1>
    </div>
  )
}

export default OrderOnline